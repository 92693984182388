.form {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 15px;
  grid-row-gap: 40px;
}
.form._margin {
  margin-bottom: 60px;
}

.form-margin {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.form-complete {
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  min-height: 270px;
}
.form-complete .descriptionText {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.input-block {
  width: 340px;
  display: flex;
  flex-direction: column;
}
.input-block._textarea {
  width: 100%;
}
.input-block._margin-large {
  margin-bottom: 40px;
}
.input-block._margin-small {
  margin-bottom: 25px;
}
.input-block._margin-mini {
  margin-bottom: 15px;
}
.input-block .label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin-bottom: 6px;
}
.input-block input,
.input-block .input,
.input-block textarea {
  background: #F9F9F9;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  width: calc(100% - 42px);
  padding: 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0;
  outline: none;
}
.input-block input::placeholder,
.input-block .input::placeholder,
.input-block textarea::placeholder {
  color: var(--gray-color);
  opacity: 1;
}
.input-block input:-ms-input-placeholder,
.input-block .input:-ms-input-placeholder,
.input-block textarea:-ms-input-placeholder {
  color: var(--gray-color);
}
.input-block input::-ms-input-placeholder,
.input-block .input::-ms-input-placeholder,
.input-block textarea::-ms-input-placeholder {
  color: var(--gray-color);
}
.input-block .input {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.input-block .input input[type=file] {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  border: none;
}
.input-block .input .placeholder {
  color: var(--gray-color);
}
.input-block .input span {
  flex-grow: 1;
}
.input-block .input .icon {
  height: 15px;
}
.input-block textarea {
  resize: none;
}
.input-block .form-link {
  padding-top: 30px;
}
.input-block .form-link div {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.input-block .form-link div a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: var(--secondary-color);
}

.form-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
}
.form-buttons .box {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 17px;
}

.text-total {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #003A48;
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .form {
    grid-template-columns: 1fr;
    grid-row-gap: 18px;
  }
  .form._margin {
    margin-bottom: 30px;
  }

  .form-margin {
    margin-bottom: 30px;
  }

  .form-complete {
    display: flex;
    flex-direction: column;
    margin: 35px 0 15px;
    min-height: calc(100vh - 50px - 270px - 78px);
  }
  .form-complete .descriptionText {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }

  .input-block {
    width: 100%;
  }
  .input-block._margin-large {
    margin-bottom: 18px;
  }
  .input-block .form-link {
    padding-top: 12px;
  }
  .input-block .form-link div {
    min-height: unset;
    justify-content: center;
    padding-left: 0;
  }
  .input-block .form-link div a {
    text-align: center;
  }
  .input-block input,
.input-block .input,
.input-block textarea {
    font-size: 16px;
  }

  .form-buttons {
    margin-bottom: 45px;
  }
  .form-buttons .box {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .text-total {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

