.footer{
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	background-color: #F2F2F2;
}

@media (max-width: 1200px) {
	.footer{
		display: none;
	}
}