.profile-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 30px;
}
.profile-block .account {
  display: flex;
  align-items: center;
}
.profile-block .account .photo {
  width: 82px;
  height: 82px;
  margin-right: 25px;
}
.profile-block .account .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.profile-block .account .info {
  display: flex;
  flex-direction: column;
}
.profile-block .account .info p {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: var(--dark-blue-color);
  margin: 0 0 4px;
}
.profile-block .account .info p.name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.profile-block .edit {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.profile-block .edit img {
  height: 16px;
  margin-right: 6px;
}
.profile-block .edit span {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@media (max-width: 1200px) {
  .profile-block {
    padding: 25px 0;
  }
  .profile-block .account .photo {
    margin-right: 15px;
  }
  .profile-block .account .info p {
    font-size: 12px;
    line-height: 14px;
  }
  .profile-block .account .info p.name {
    font-size: 14px;
    line-height: 24px;
  }
}

