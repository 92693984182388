.banner {
  height: 260px;
  width: 100%;
  background: var(--placeholder-color);
}
.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .banner {
    height: 180px;
  }
}

