.checkbox-block{
	display: flex;
	margin-bottom: 30px;

	.checkbox{
		width: 21px;
		height: 21px;
		border: 2px solid var(--blue-color);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 5px;
		margin-right: 15px;
		flex-shrink: 0;

		&.active{
			background-color: var(--blue-color);
		}
	}

	.label{
		font-weight: 400;
		font-size: 14px;
		line-height: 25px;
		letter-spacing: 0.2px;
		color: var(--gray-color);

		a{
			text-decoration: none;
			color: var(--blue-color);
		}
	}
}