.run-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 110px;
}
.run-data .head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.run-data .head div {
  padding: 15px 0 15px 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
}
.run-data .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.run-data .row div {
  padding: 10px 0 10px 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-color);
}
.run-data .row div:first-child {
  padding-left: 30px;
}
.run-data .row:nth-child(2n) {
  background-color: #FFFBEC;
}

@media (max-width: 768px) {
  .run-data {
    margin-bottom: 70px;
  }
  .run-data .head div {
    padding: 10px 0 10px 0;
    font-size: 14px;
  }
  .run-data .head div:first-child {
    padding-left: 10px;
  }
  .run-data .row div {
    padding: 10px 0 10px 5px;
    font-size: 10px;
    line-height: 20px;
  }
  .run-data .row div:first-child {
    padding-left: 5px;
  }
}

