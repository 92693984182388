.menu {
  height: 40px;
  padding: 25px calc((100% - 1100px) / 2);
  background-color: var(--menu-color);
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu .right .burger {
  display: none;
}
.menu .right .submenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu .right .submenu .items {
  display: flex;
  align-items: center;
  margin-right: 55px;
}
.menu .right .submenu .items .item {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--gray-text);
  margin-right: 15px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
}
.menu .right .submenu .items .item.active, .menu .right .submenu .items .item:hover {
  color: var(--white-color);
}
.menu .right .submenu .profile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu .right .submenu .profile .avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.menu .right .submenu .profile .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.menu .right .submenu .profile .logout {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.menu .right .submenu .profile .logout img {
  height: 18px;
  margin-right: 8px;
}
.menu .right .submenu .profile .logout span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-text);
  text-transform: uppercase;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .menu {
    height: 14px;
    padding: 22px 15px;
    width: calc(100% - 30px);
  }
  .menu .logo {
    height: 13px;
  }
  .menu .right .burger {
    width: 22px;
    height: 14px;
    position: relative;
    display: block;
  }
  .menu .right .burger:before, .menu .right .burger:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--gray-color);
  }
  .menu .right .burger:before {
    transform: translateY(-7px);
  }
  .menu .right .burger:after {
    transform: translateY(6px);
  }
  .menu .right .burger .line {
    width: 100%;
    height: 2px;
    background-color: var(--gray-color);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .menu .right .submenu {
    background-color: var(--menu-color);
    display: block;
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;
  }
  .menu .right .submenu .profile {
    display: none;
  }
  .menu .right .submenu .items {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 0;
  }
  .menu .right .submenu .items .item {
    margin: 0 0 20px 0;
  }
  .menu .right .submenu.open {
    max-height: 250px;
  }
}

