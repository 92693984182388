.row-title {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.row-title img {
  height: 24px;
  margin-right: 9px;
}
.row-title span {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: var(--dark-blue-color);
}

@media (max-width: 1200px) {
  .row-title {
    margin-bottom: 20px;
  }
  .row-title img {
    height: 18px;
    margin-right: 5px;
  }
  .row-title span {
    font-size: 18px;
    line-height: 24px;
  }
  .row-title_tab {
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    padding: 9px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

