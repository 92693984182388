.selector{
	display: flex;
	flex-wrap: wrap;

	&._margin_large{
		margin-bottom: 45px;
	}

	&._margin_small{
		margin-bottom: 17px;
	}

	.item{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 10px;
		min-width: 65px;
		border: 1px solid #E4E4E4;
		margin: 0 8px 8px 0;
		cursor: pointer;

		p{
			text-align: center;
			font-style: normal;
			font-weight: 700;
			font-size: 13px;
			line-height: 28px;
			letter-spacing: 0.2px;
			color: var(--gray-color);
			margin: 0;
		}

		&.active{
			border-color: var(--blue-color);
			p{
				color: var(--blue-color);
			}
		}
	}

	&._large{
		.item{
			margin: 0 22px 22px 0;
			padding: 25px;
			min-width: 260px;
			border-radius: 5px;
		}
	}

	&._medium{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 20px;

		.item{
			margin: 0;
			padding: 15px;
			min-width: unset;
			border-radius: 5px;
		}
	}
}

@media (max-width: 768px) {
	.selector{
		&._margin_large{
			margin-bottom: 40px;
		}
		&._margin_small{
			margin-bottom: 20px;
		}

		.item{
			p{
				text-align: center;
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 28px;
				letter-spacing: 0.2px;
				color: var(--gray-color);
				margin: 0;
			}

			&.active{
				border-color: var(--blue-color);
				p{
					color: var(--blue-color);
				}
			}
		}

		&._large{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 15px;
			grid-row-gap: 15px;

			.item{
				margin: 0;
				padding: 15px;
				min-width: unset;
				border-radius: 5px;

				p{
					font-size: 13px;
					line-height: 16px;
				}
			}
		}

		&._medium{
			display: grid;
			grid-template-columns: 1fr;
			grid-row-gap: 15px;

			.item{
				margin: 0;
				padding: 15px;
				min-width: unset;
				border-radius: 5px;
			}
		}
	}
}