.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: calc(100vh - 170px);
}

@media (max-width: 1200px) {
  .wrapper {
    min-height: calc(100vh - 58px);
  }
}

