.race-data {
  background: #181818;
  width: 1100px;
  margin: 50px auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.race-data .block {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 25px;
  border-right: 1px solid #474545;
}
.race-data .block .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--white-color);
  margin: 0;
}
.race-data .block .value {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--primaty-color);
  margin: 0;
}

@media (max-width: 768px) {
  .race-data {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
  }
  .race-data .block {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 25px;
    border-right: 1px solid #474545;
    border-bottom: 1px solid #474545;
  }
}

