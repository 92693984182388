body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: var(--white-color);
}

:root {
  --primaty-color: #FFCB05;
  --secondary-color: #003A48;
  --dark-blue-color: #252B42;
  --black-color: #212121;
  --menu-color: #181818;
  --white-color: #ffffff;
  --gray-text: #979797;
  --gray-color: #737373;
  --placeholder-color: #C4C4C4;
  --green-color: #12978F;
  --blue-color: #23A6F0;
}

.container {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.titleText1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0 0 10px 0;
}

.titleText2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0 0 10px 0;
}

.descriptionText {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--black-color);
  margin: 0 0 35px 0;
}
.descriptionText a {
  text-decoration: none;
  color: var(--blue-color);
}

.note {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--black-color);
  margin: 0 0 10px 0;
}
.note a, .note .link {
  color: var(--blue-color);
  cursor: pointer;
}

.accent {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--green-color);
  margin: 0 0 20px 0;
}

.details-block {
  background: #FFFBEC;
  padding: 30px 55px;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 55px;
}
.details-block p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-color);
  margin: 0 0 10px 0;
}
.details-block .label {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0 0 10px 0;
}
.details-block .desc {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--black-color);
  margin: 0 0 10px 0;
}
.details-block .link {
  color: var(--blue-color);
  text-decoration: underline;
  cursor: pointer;
}

.review-box {
  width: 660px;
  display: flex;
  flex-direction: column;
}
.review-box .title {
  color: var(--green-color);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 25px 0;
}
.review-box .section {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  margin-bottom: 60px;
}
.review-box .section .item {
  display: flex;
  flex-direction: column;
}
.review-box .section .item .label, .review-box .section .item .value {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--black-color);
  margin: 0;
}
.review-box .section .item .label {
  font-weight: 600;
}

.paypal-container {
  width: 500px;
  max-width: 90%;
  margin: 0 auto 80px;
}

@media (max-width: 1200px) {
  .container {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .descriptionText {
    font-size: 12px;
    line-height: 14px;
  }

  .note {
    margin: 0 0 5px 0;
  }

  .details-block {
    background: #FFFBEC;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 35px;
  }

  .review-box {
    width: 660px;
    display: flex;
    flex-direction: column;
  }
  .review-box .title {
    background: #FFFBEC;
    color: var(--green-color);
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 -20px 20px -20px;
    padding: 10px 20px;
    width: 100%;
  }
  .review-box .section {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-bottom: 35px;
  }

  .paypal-container {
    width: 500px;
    max-width: 90%;
    margin: 30px auto;
  }
}

