.race-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.race-list .list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 35px;
}

.race-list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.race-list-item .img {
  width: 100%;
  min-height: 160px;
  background-color: var(--placeholder-color);
}
.race-list-item .img img {
  width: 100%;
}
.race-list-item .data {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 5px;
}
.race-list-item .data .block {
  width: 100%;
  padding: 0 15px;
  margin: 17px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.race-list-item .data .block:nth-child(n+2):before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #E0E0E0;
  position: absolute;
  top: 0;
  left: 0;
}
.race-list-item .data .block .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0;
}
.race-list-item .data .block .value {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--green-color);
  margin: 0;
}
.race-list-item .btn {
  width: 100%;
}
.race-list-item .info {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.race-list-item .info .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0;
}
.race-list-item .info .desc {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--black-color);
  margin: 0 0 9px 0;
}
.race-list-item .info .param {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--black-color);
  margin: 0;
}

@media (max-width: 1200px) {
  .race-list {
    margin-bottom: 60px;
  }
  .race-list .list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .race-list {
    margin-bottom: 35px;
  }
  .race-list .list {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
}

