.input-number {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
}
.input-number .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 190px;
  padding-right: 10px;
}
.input-number .text .label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0;
}
.input-number .text .subtext {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--gray-text);
  margin: 0;
}
.input-number .counter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
}
.input-number .counter .button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.input-number .counter .button_minus {
  background: #C4C4C4;
}
.input-number .counter .button_plus {
  background: var(--blue-color);
}
.input-number .counter .value {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  padding: 0 10px;
  min-width: 35px;
  text-align: center;
}

@media (max-width: 768px) {
  .input-number {
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .input-number .text {
    min-width: unset;
  }
  .input-number .text .label {
    font-size: 14px;
    line-height: 24px;
  }
  .input-number .text .subtext {
    font-size: 10px;
    line-height: 12px;
  }
  .input-number .counter {
    padding-right: 10px;
  }
}

