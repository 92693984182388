.menu{
	height: 40px;
	padding: 25px calc((100% - 1100px) / 2);
	background-color: var(--menu-color);
	width: 1100px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.right{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.burger{
			display: none;
		}

		.submenu{
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.items{
				display: flex;
				align-items: center;
				margin-right: 55px;

				.item{
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					color: var(--gray-text);
					margin-right: 15px;
					cursor: pointer;
					text-transform: uppercase;
					text-decoration: none;

					&.active,
					&:hover{
						color: var(--white-color);
					}
				}
			}

			.profile{
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.avatar{
					cursor: pointer;
					width: 40px;
					height: 40px;
					margin-right: 10px;

					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 100%;
					}
				}

				.logout{
					display: flex;
					align-items: center;
					text-decoration: none;
					cursor: pointer;

					img{
						height: 18px;
						margin-right: 8px;
					}

					span{
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--gray-text);
						text-transform: uppercase;
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.menu{
		height: 14px;
		padding: 22px 15px;
		width: calc(100% - 30px);

		.logo{
			height: 13px;
		}

		.right{
			.burger{
				width: 22px;
				height: 14px;
				position: relative;
				display: block;

				&:before,
				&:after{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					width: 100%;
					height: 2px;
					background-color: var(--gray-color);
				}

				&:before{
					transform: translateY(-7px);
				}

				&:after{
					transform: translateY(6px);
				}

				.line{
					width: 100%;
					height: 2px;
					background-color: var(--gray-color);
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.submenu{
				background-color: var(--menu-color);
				display: block;
				position: absolute;
				top: 58px;
				left: 0;
				width: 100%;
				max-height: 0;
				transition: max-height 0.5s;
				overflow: hidden;

				.profile{
					display: none;
				}

				.items{
					display: flex;
					align-items: center;
					flex-direction: column;
					margin: 20px 0 0;

					.item{
						margin: 0 0 20px 0;
					}
				}

				&.open{
					max-height: 250px;
				}
			}
		}
	}
}