.login {
  background: url("../../assets/bg.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.login .content {
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 45px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 340px;
}
.login .logo {
  margin-bottom: 10px;
}
.login p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
  margin: 0 0 30px 0;
  text-align: center;
}
.login .btn-facebook {
  background: #1877F2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  padding: 15px 0;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-decoration: none;
}
.login .btn-facebook svg {
  margin-right: 15px;
}
.login .btn-google {
  border: 1px solid #777777;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 13px 0;
  width: calc(100% - 2px);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
}
.login .btn-google svg {
  margin-right: 15px;
}
.login .separator {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.login .separator:before, .login .separator:after {
  content: "";
  display: block;
  height: 1px;
  flex-grow: 1;
  background-color: #DADADA;
}
.login .separator span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #252B42;
  padding: 0 12px;
}
.login .forgot {
  width: 100%;
  margin: -7px 0 20px;
  text-align: right;
}
.login .forgot a {
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.1px;
  color: var(--dark-blue-color);
}
.login .submit {
  background: #FFCB05;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--secondary-color);
  padding: 13px 0;
  width: 100%;
  margin-bottom: 35px;
  cursor: pointer;
}
.login .reg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #000000;
}
.login .reg a {
  text-decoration-line: underline;
  color: var(--secondary-color);
  margin-left: 14px;
}
@media (max-width: 768px) {
  .login {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: 0;
  }
  .login .content {
    width: calc(100% - 40px - 50px);
    padding: 55px 25px;
    margin: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    min-width: unset;
  }
}
@media (max-width: 350px) {
  .login .content {
    width: calc(100% - 20px - 20px);
    padding: 30px 10px;
    margin: 10px 10px;
  }
}

