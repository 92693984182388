.btn{
	width: 340px;
	border-radius: 4px;
	padding: 16px 0;
	text-align: center;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	cursor: pointer;
	margin-top: 20px;
	text-transform: uppercase;
	text-decoration: none;

	&-default{
		border: 1px solid var(--secondary-color);
		color: var(--secondary-color);
		&:hover{
			background-color: var(--secondary-color);
			color: var(--white-color);
		}
	}

	&-primary{
		border: 1px solid var(--primaty-color);
		background-color: var(--primaty-color);
		color: var(--secondary-color);
		&:hover{
			background-color: transparent;
		}
	}
}

@media (max-width: 768px) {
	.btn{
		width: calc(100% - 2px);
	}
}