.form{
	display: grid;
	grid-template-columns: repeat(2, min-content);
	grid-column-gap: 15px;
	grid-row-gap: 40px;

	&._margin{
		margin-bottom: 60px;
	}
}

.form-margin{
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;
}

.form-complete{
	display: flex;
	flex-direction: column;
	margin: 60px 0;
	min-height: 270px;

	.descriptionText{
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
	}
}

.input-block{
	width: 340px;
	display: flex;
	flex-direction: column;

	&._textarea{
		width: 100%;
	}

	&._margin-large{
		margin-bottom: 40px;
	}

	&._margin-small{
		margin-bottom: 25px;
	}

	&._margin-mini{
		margin-bottom: 15px;
	}

	.label{
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.1px;
		color: var(--dark-blue-color);
		margin-bottom: 6px;
	}

	input,
	.input,
	textarea{
		background: #F9F9F9;
		border: 1px solid #E6E6E6;
		border-radius: 5px;
		width: calc(100% - 42px);
		padding: 10px 20px;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 28px;
		letter-spacing: 0.2px;
		margin: 0;
		outline: none;

		&::placeholder {
			color: var(--gray-color);
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: var(--gray-color);
		}

		&::-ms-input-placeholder {
			color: var(--gray-color);
		}
	}

	.input{
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		input[type=file]{
			width: 1px;
			height: 1px;
			overflow: hidden;
			opacity: 0;
			position: absolute;
			padding: 0;
			margin: 0;
			border: none;
		}

		.placeholder{
			color: var(--gray-color);
		}

		span{
			flex-grow: 1;
		}

		.icon{
			height: 15px;
		}
	}

	textarea{
		resize: none;
	}

	.form-link{
		padding-top: 30px;

		div{
			min-height: 50px;
			display: flex;
			align-items: center;
			padding-left: 15px;

			a{
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.2px;
				text-decoration-line: underline;
				color: var(--secondary-color);
			}
		}

	}
}

.form-buttons{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 120px;

	.box {
		display: grid;
		grid-template-columns: repeat(2, min-content);
		grid-column-gap: 17px;
	}
}

.text-total{
	text-align: center;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.2px;
	color: #003A48;
	margin-bottom: 70px;
}

@media (max-width: 768px) {
	.form{
		grid-template-columns: 1fr;
		grid-row-gap: 18px;

		&._margin{
			margin-bottom: 30px;
		}
	}

	.form-margin{
		margin-bottom: 30px;
	}

	.form-complete{
		display: flex;
		flex-direction: column;
		margin: 35px 0 15px;
		min-height: calc(100vh - 50px - 270px - 78px);

		.descriptionText{
			font-weight: 300;
			font-size: 12px;
			line-height: 14px;
		}
	}

	.input-block{
		width: 100%;

		&._margin-large{
			margin-bottom: 18px;
		}

		.form-link{
			padding-top: 12px;

			div{
				min-height:  unset;
				justify-content: center;
				padding-left: 0;

				a{
					text-align: center;
				}
			}
		}

		input,
		.input,
		textarea{
			font-size: 16px;
		}
	}

	.form-buttons{
		margin-bottom: 45px;
		.box {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
		}
	}

	.text-total{
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 25px;
	}
}
