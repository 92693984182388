.profile-block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 35px 0;
	border-bottom: 1px solid #DADADA;
	margin-bottom: 30px;

	.account{
		display: flex;
		align-items: center;

		.photo{
			width: 82px;
			height: 82px;
			margin-right: 25px;

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 100%;
			}
		}

		.info{
			display: flex;
			flex-direction: column;

			p{
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;
				color: var(--dark-blue-color);
				margin: 0 0 4px;

				&.name{
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.1px;
				}
			}
		}
	}

	.edit{
		display: flex;
		align-items: center;
		text-decoration: none;

		img{
			height: 16px;
			margin-right: 6px;
		}

		span{
			color: var(--secondary-color);
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
		}
	}
}

@media (max-width: 1200px) {
	.profile-block {
		padding: 25px 0;

		.account{
			.photo{
				margin-right: 15px;
			}

			.info{
				p{
					font-size: 12px;
					line-height: 14px;

					&.name{
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
	}
}