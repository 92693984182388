.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin-bottom: 30px;
}
.progress-bar .dot {
  width: 12px;
  height: 12px;
  border: 4px solid #D7D7D7;
  border-radius: 100%;
  margin: 0 3px;
  flex-shrink: 0;
}
.progress-bar .dot.active {
  border-color: var(--primaty-color);
}
.progress-bar .line {
  width: 85px;
  height: 2px;
  background-color: #D7D7D7;
  margin: 0 3px;
}

@media (max-width: 768px) {
  .progress-bar {
    padding: 15px 0;
    width: 265px;
    margin: 0 auto 15px;
  }
}

